.Logout .modal-header {
  border-bottom: 0px;
}
.Logout .modal-footer {
  border-top: 0px;
}
.Logout p.text {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.Logout button.btn.btn-primary {
  border: none;
  background-color: #1693d9;
  border-radius: 0px;
  font-size: 15px;
}

.Payment_Screenshotmodal .Payment_Screenshot_img_holder {
  aspect-ratio: 1/2;
  border: 1px solid #000;
  width: 100%;
  margin: 0px auto;
}

.select-drop.css-b62m3t-container {
  width: 20%;
}

.datetimepicker-holder .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 10px;
  border: 12px;
}

.Payment_Screenshotmodal .modal-title {
  font-size: 20px;
  font-weight: 600;
}

.entries-section p {
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
