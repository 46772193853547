@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
body {
    font-family: "Poppins", "sans-serif" !important;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}
.top_section .sub-header .main-sub-header {
    align-items: baseline;
    display: flex;
}
.top_section .sub-header .admin-header {
    padding: 1px 18px;
    justify-content: end;
    display: flex;
    align-items: end;
}
.top_section .sub-header {
    position: sticky;
    top: 0;
    z-index: 99;
    height: 50px;
    background-color: #217dcb;
}
.top_section .sub-header .main-sub-header h3.Itlogtitle {
    font-weight: 100;
    color: white;
    font-size: 21px;
}
.top_section .sub-header .main-sub-header .icon {
    font-size: 22px;
    margin: 0px 10px;
    color: white;
}
.top_section .sub-header .user {
    border-radius: 100px;
    height: 25px;
    width: 25px;
    background-color: white;
    padding: -1px 0px;
}
.top_section .sub-header .user-icon {
    padding: 5px 5px;
}
.top_section .sub-header .text {
    color: white;
    font-size: 15px;
}
.date-range-picker-container {
    width: 100%;
}
.page-link:focus {
    z-index: 3;
    color: black;
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: transparent !important;
}
.DateInput_input {
    font-weight: 200;
    font-size: 11px !important;
}
input {
    font-size: 13px !important;
    height: 35px;
    border: 1px solid #cfc8c8;
}
a.page-link {
    color: black;
    font-size: 14px;
}
label {
    font-size: 14px;
    color: black;
    margin-right: 10px;
}

.btn-submit {
    border-radius: 0px;
    border: none;
    color: black;
    font-size: 17px;
    background-color: #1DA4C9;
    padding: 5px 30px;
    font-weight: 500;
}





.sidebar {
    width: 40px;
    transition: width 0.3s;
    overflow: hidden;
    background-color: #333;
    color: #fff;
    /* height: 100vh; */
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    /* padding: 21px 29px; */
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar ul li .icon-text {
    display: flex;
    align-items: center;
    width: 100%;
}

.sidebar ul li .icon {
    margin-right: 10px;
}

.sidebar ul li p {
    margin: 0;
    display: inline-block;
}

.link-body-emphasis {
    color: #fff;
    text-decoration: none;
    padding: 14px 0px 14px 30px;
    width: 100%;
}
a:hover {
    color: white !important;
}
.page-link:hover {
    z-index: 2;
    color: black !important;
    background-color: var(--bs-pagination-hover-bg);
    border-color: black;
}
.page-link:focus {
  
    box-shadow:grey !important;
}

.main-content.full-width {
    margin-left: 40px;
}

.main-content.reduced-width {
    margin-left: 300px;
}
.sidebar ul li:hover a {
    background-color: #3c4f5e;

}

.sidebar ul li.active a {
    background-color: #3c4f5e;

}
.main-advancedashboard {
    transition: margin-left 0.3s ease;
}
.main-advancedashboard {
    /* height: 100vh; */
    height: calc(100vh - 50px);
    overflow-y: scroll;
    padding-bottom: 61px;
    /* scrollbar-width: thin; */
    background-color: #f9f9f9;
}
/* section.AdvanceDashboard {
    margin-top: 18px;
} */



/* App.css */

.top_section {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sub-header {
    background-color: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.icon {
    cursor: pointer;
}
.table-main {
    width: 100%;
    overflow-x: auto;
}

.table-main .odd .form-control {
    width: auto;
}




.admin-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.admin-header .user {
    margin-right: 10px;
}

.admin-header .text p {
    margin: 0;
}



.sidebar-column {
    padding: 0;
    overflow: hidden;
    transition: width 0.3s ease;
}

.main-content {
    transition: margin-left 0.3s ease;
    width: 100%;
    padding: 0px;
}


/* Accordion css */
.sidebar button.accordion-button.collapsed {
    padding: 17px 32px;
}
.sidebar .accordion-button:not(.collapsed) {
    padding: 17px 32px;
    box-shadow: none;
}
li.setting {
    margin: 0px -18px;
    padding: 0px -22px;
}







/* operation */
.operation .form-group {
    display: flex;
    align-items: baseline;
}


@media (min-width: 992px) and (maxwidth: 1199px) {
    th.name {
        font-weight: 500;
        font-size: 13px;
    }
}
@media (min-width: 1199px) and (maxwidth: 1300px) {
    th.name {
        font-weight: 500;
        font-size: 12px;
    }
}
