thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.addasbordssd{
  height: calc( 100vh - 50px) !important;
  overflow-y: scroll !important;
  scrollbar-width: thin !important;
}

.psitionsss {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 98;
}
.psitionsss22{
  position: sticky;
  top: 51px;
  background-color: #fff;
  z-index: 98;
}
.AdvanceDashboardTabs .Graph {
  padding: 10px;
}




.tab-radio .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: none;
}
.tab-radio .btn-primary {
  height: 38px;
  background-color: #000 !important;
  border: 1px solid #000 !important;
}
.tab-radio .btn-danger {
  background-color: #6c757d !important;
  border: 1px solid #6c757d !important;
  height: 38px;
}
.tab-radio .breadcrumb a {
  border-bottom: none;
  color: none !important;
  text-decoration: none !important;
}

.tab-radio .btn-add {
  color: white;
  font-size: 14px;
  width: 100%;
  background: #dc3545 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.tab-radio .btn-add:hover {
  color: white;
  font-size: 14px;
  background: #dc3545 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.tab-radio .btn-columns {
  color: white;
  font-size: 14px;
  /* width: 100%; */
  background: #dc3545 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.tab-radio .btn-columns:hover {
  color: white;
  font-size: 14px;
  background: #dc3545 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.tab-radio .btn-download {
  background: #e89300 0% 0% no-repeat padding-box;
}

.tab-radio .btn-download:hover {
  background: #e89300 0% 0% no-repeat padding-box;
}

.tab-radio .add .columns {
  width: 13px;
  height: auto;
  margin-right: 10px;
  display: none;
}

.tab-radio .add .plus {
  width: 17px;
  height: 17px;
  display: none;
}

.tab-radio .border-line {
  border-bottom: 1px solid #707070;
}

.tab-radio .breadcrumb .breadcrumb-item a {
  color: none !important;
  text-decoration: none !important;
}

.tab-radio .table .circle {
  border-radius: 100px;
  height: 9px;
  width: 9px;
  background: #019e11;
}

.tab-radio .table .circle1 {
  border-radius: 100px;
  height: 9px;
  width: 9px;
  background: red;
}

.tab-radio td {
  font-weight: 500;
  color: #292929;
  font-size: 13px;
}

.tab-radio table.table.table-bordered.tableborder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
}

.tab-radio p.show {
  font-size: 12px;
  color: black;
  font-weight: 700;
}

.tab-radio .form-check-input:checked:focus {
  box-shadow: none;
}

.tab-radio select.form-select.form-select-sm:focus {
  box-shadow: none;
}

.tab-radio .num .form-control:focus {
  box-shadow: none;
}

.tab-radio .data .table th.tax-name {
  width: 30%;
  color: #292929;
  font-size: 13px;
}

.tab-radio .data .table td.tax-rate {
  text-align: center;
}

.tab-radio .data .table th.tax {
  font-size: 13px;
  color: #292929;
}

.tab-radio .data .table th.rate {
  font-size: 13px;
  color: #292929;
  text-align: center;
}

.tab-radio .data .table th.status {
  font-size: 13px;
  color: #292929;
}

.tab-radio .data .table th.active {
  font-size: 13px;
  color: #292929;
}

li.breadcrumb-item {
  color: #555555;
  font-size: 13px;
}

.tab-radio {
  margin-left: 1%;
  margin-right: 1%;
}

/* .tab-radio .round-check .form-check-input:checked {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
}

.tab-radio .round-check .form-check-input:checked[type=checkbox] {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
  background-image: url('../../assets/icons/check.png');
}

.tab-radio .round-check .form-check-input[type=checkbox] {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
}

.tab-radio .round-check .form-check-input[type=checkbox]:focus {
  box-shadow: none;
}


*/

.tab-radio .btn-sea {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

.tab-radio .form-switch .form-check-input {
}

/* .tab-radio select.form-select.form-select-sm {
  border: 1px solid #707070;
  height: 27px;
} 

.tab-radio button.btn.btn-search {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  height: 27px;
  width: 222%;
}

.tab-radio .num .form-control {
  border: 1px solid #707070;
  height: 28px !important;
  width: 64% !important;
}

.Search {
  position: relative;
  left: -10%;
}

.tab-radio .search {
  left: 20px;
  position: absolute;
  top: 5px;
}


*/

.tab-radio .btn-reset {
  background-color: #6c757d;
  color: #fff;
  font-size: 14px;
  /* height: 27px; */

  text-align: center;
}

.tab-radio .btn-reset:hover {
  background-color: #6c757d;
  color: #fff;
  font-size: 14px;
  /* height: 27px; */

  text-align: center;
}

.tab-radio select.form-select.form-select-sm {
  border: 1px solid #707070;
  /* height: 27px; */
}
/* .Search {
  position: relative;
  left: -10%;
  } */
.tab-radio .btn-search {
  background: #000 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /* height: 27px; */
  width: 64px;
}

.AdvanceDashboard .Tabels .dropdown-item {
  font-size: 13px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.tab-radio .num .form-control {
  border: 1px solid #707070;
  /* height: 28px !important; */
  /* width: 64% !important; */
}

.col-form-label {
  font-size: 13px;
}
/* Media Query */

@media (min-width: 280px) and (max-width: 320px) {
  section.Master-Manegement {
    height: auto;
  }

  .tab-radio button.btn.btn-add {
    width: 64%;
  }

  .tab-radio button.btn.btn-columns {
    width: 64%;
    margin-left: 0px;
  }

  .tab-radio .add {
    width: auto;
  }

  .tab-radio .add .columns {
    left: 5px;
  }

  .tab-radio .add .plus {
    left: 23px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  section.Master-Manegement {
    height: auto;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
    font-size: 12px;
  }

  .tab-radio button.btn.btn-columns {
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
  }

  /* .tab-radio .add .plus {
  left: 14px;
  
}
.tab-radio .add .columns {
 left: 5px;
} */
}

@media (min-width: 486px) and (max-width: 576px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio button.btn.btn-columns {
    width: 100%;
    margin-left: 0px;
    font-size: 13px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
    font-size: 13px;
  }

  .tab-radio .add .columns {
    left: 7px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio button.btn.btn-columns {
    width: 100%;
    margin-left: 0px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
  }

  .tab-radio .add .columns {
    left: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  button.btn.btn-danger {
    /* margin-top: 8%; */
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio button.btn.btn-columns {
    width: 100%;
    margin-left: 0px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
  }

  .tab-radio .add .columns {
    left: 7px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  button.btn.btn-danger {
    /* margin-top: 6%; */
  }
}

.main-section .dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.main-section ul.dropdown-menu.show {
  background: #f3feff 0% 0% no-repeat padding-box;
}

.main-section .dropdown ul.dropdown-menu.show:focus {
  box-shadow: none;
}

.main-section .tab-radio .data .table th.tax1 {
  width: 24%;
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.sub1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.include1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.rate1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.status1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.active1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table td.ten {
  text-align: center;
}

/* .main-section .tab-radio .add .dropdown .dropdown-menu  img.eye1 {
  position: absolute;
  top: 11px;
  right: 13px;
  height: 11px;
} */
.main-section .tab-radio .add .dropdown .dropdown-menu img.eye1 {
  position: absolute;
  right: 13px;
  width: 11%;
}

.main-section .col-form-label {
  font-weight: 500;
  font-size: 13px;
}

.main-section select.form-select {
  height: 28px;
  width: 100%;
}

.main-section .District p.district {
  font-weight: 500;
  font-size: 13px;
  color: #292929;
}

.main-section .District select.form-select {
  font-weight: 500;
  font-size: 12px;
}

.main-section .view-details .btn-view {
  color: white;
  font-size: 14px;
  width: 100%;
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.main-section .view-details .eye-img {
  width: 21px;
  height: 16px;
}

.main-section .view-details {
  margin-left: auto;
}

/*********modal start******/
.upload-modal .modal-content {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 201px;
}

.upload-modal .modal-content .circle .circle-img {
  width: 52px;
  height: 52px;
}
.upload-modal .modal-content .add-success {
  color: #efefef;
  font-size: 25px;
}
.upload-modal .modal-content .modal-body p {
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.upload-modal .btn-yes {
  background-color: #efefef;
  color: red;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}
.upload-modal .btn-yes:hover {
  background-color: #efefef;
  color: red;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}
.upload-modal .btn-no {
  background-color: #efefef;
  color: #000;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}
.upload-modal .btn-no:hover {
  background-color: #efefef;
  color: #000;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}

.save-modal .modal .modal-content {
  /* height: 201px; */
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.save-modal .modal .modal-body .circle .circle-img {
  margin-top: 2%;
  margin-left: 37%;
  width: 114px;
  height: 114px;
}

.save-modal .modal .modal-body .add-success {
  color: white;
  font-size: 25px;
}

@media (min-width: 0px) and (max-width: 768px) {
  button.btn.btn-danger {
    /* margin-top: 6%; */
    font-size: 12px;
    display: flex;
  }

  .btn.btn-success {
    font-size: 12px;
    display: flex;
  }
  .save-img {
    margin-top: 3px;
  }
  .cancel-img {
    margin-top: 3px;
  }
  .tab-radio .btn-add {
    font-size: 12px;
  }
  .main-form-section {
    margin-top: 10px !important;
  }
  .main-form-section .form-control {
    font-size: 12px;
  }
  .shadow-lg {
    padding: 0px I !important;
  }
}

/* neww css  */
.investor .p.sub {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}




.investor .date {
  display: flex;
  justify-content: space-between;
}

.search-investor {
  border-top: 2px solid #00c2ff;
  margin: 15px 16px;
  background-color: white;
  padding: 15px 12px;
}

.investor h4.title {
  /* margin: 0px 21px; */
  padding-bottom: 5px;
  font-size: 18px;
  border-bottom: 1px solid #c3c2c2;
}
.investor p.sub {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}
.investor .custom-select-class__value-container {
  height: 30px;
}
.investor .custom-select-class__value-container {
  height: 30px;
}
.investor .search {
  padding: 8px 0px;
  margin: 22px 0px;
  align-items: baseline;
  justify-content: center;
  display: flex;
  text-align: center;
  height: 38px;
  width: 100%;
  background-color: #6953a3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.investor .search p {
  margin-bottom: 0px;
}
.investor svg.svg-inline--fa.fa-magnifying-glass {
  margin: 1px 8px;
}
.investor .custom-select-class__input-container {
  height: 21px;
}

.investor-main {
  margin: 0px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
}

.investor-main .sub {
  margin-bottom: 0px;
}

button.btn.btn-secondary {
  color: black;
  background-color: #e3e3e3;
  border: none;
}

.Subscriber .table tr th {
  font-size: 13px;
  font-weight: 600;
}

.Subscriber .table tr td {
  font-size: 12px;
  text-align: start;
  white-space: pre-wrap;
  /* width: 45%; */
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.Subscriber .table tr th {
  font-size: 13px;
  font-weight: 600;
}
.triangle {
  top: 9px;
  position: absolute;
  width: 0;
  right: 5px;
  height: 0;
  /* border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid red; */
}
.triangle2 {
  top: 19px;
  right: 5px;
  position: absolute;
  width: 0;
  height: 0;
  /* border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid green; */
}
.table-bordered > :not(caption) > * > * {
  position: relative;
  border-width: 0 1px;
}
th.name {
  font-weight: 500;
  font-size: 14px;
  text-wrap: nowrap;
}

.blue {
  height: 30px;
  background-color: #1697c5d1;
}

.main-inves {
  height: 71px;
  background-color: #3392ad;
  text-align: center;
  color: white;
  padding: 12px 0px;
  margin: 5px 0px;
  position: relative;
}

.search-investor .DateRangePickerInput__showClearDates {
  padding-right: 15px;
  width: 100% !important;
}

.search-investor .DateInput {
  width: 94px;
}

.search-investor .DateRangePickerInput_clearDates {
  margin: 0px;
}

.main-invesa {
  height: 71px;
  background-color: #3392ad;
  text-align: center;
  color: white;
  padding: 12px 0px;
}
.inward-payment {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

/* Inward css  */
.AdvanceDashboard .form-group {
  display: flex;
  align-items: baseline;
  margin: 0px 0px;
}
.button-inward button.amount {
  background-color: white;
  border-radius: 0px;
  font-size: 13px;
}
.button-inward button.submit {
  color: black;
  font-size: 12px;
  background-color: #5ecaf1;
  border-radius: 0px;
  border: none;
}
.AdvanceDashboard .button-inward .form-control {
  width: 85px;
}
.button-inward {
  text-align: end;
  display: flex;
  justify-content: end;
}

.inestement-main {
  right: 0px;
  /* padding: 4px 10px; */
  position: absolute;
  height: 100%;
  background: #fff;
  color: black;
  top: 0px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inestement-main p {
  margin-bottom: 0px;
}
/* Notification  */
.notification .form-group {
  display: flex;
}
.notification .form-control {
  margin: 0px 5px;
  width: 600px !important;
}
.notification button.submit {
  padding: 7px 33px;
  border-radius: 0px;
  border: none;
  font-size: 14px;
  background-color: #4cb6f1;
  color: black;
}

.notification li {
  color: #1e9ccf;
  list-style-type: square;
}
.notification .form-group {
  display: flex;
  align-items: center;
}

th {
  /* width: 135px; */
  font-weight: 500;
  font-size: 13px;
}
td {
  font-weight: 500;
  font-size: 13px;
}
.transaction {
  margin: 6px 7px;
  height: 0px;
  /* padding: 7px 4px; */
  border-right: 5px solid #2099eb;
}
.DateRangePicker {
  position: relative;
  display: inline-block;
  width: 100% !important;
}
.search-transaction {
  padding: 12px ;
  background-color: #f1f1f1;
}
.outward .form-control {
  width: 85px;
}
.outward-main .form-group {
  display: flex;
  align-items: baseline;
}

/* operation */
.operation {
  display: flex;
  align-items: baseline;
}
.operation form.simple-form {
  display: flex;
}
.operation span.buy {
  /* margin: 0px 7px; */
  color: green;
}
.operation span.sell {
  /* margin: 0px 7px; */
  color: red;
}

.Payment_Screenshot_img_holder {
  aspect-ratio: 1/1;
  border: 1px solid #000;
  width: 40px;
  margin: 0px auto;
}

.Payment_Screenshot_img_holder .Payment_Screenshot_img {
  width: 100%;
  height: 100%;
}

/* .operation  .textcolor-holder{
  margin: 0px 7px;
} */

.operation p.op {
  color: black;
  font-size: 15px;
  width: 125px;
}
.operation .form-group {
  display: flex;
  align-items: baseline;
}

li.list-opera {
  list-style-type: square !important;
  color: #2fa8e3 !important;
  font-size: 18px;
}

li.list-Submit-btn {
  list-style: none !important;
  margin: 5px 0px;
}

.operation .form-group {
  display: flex;
  align-items: baseline;
}
.operation .Submit {
  margin-left: 2%;
  /* margin: 0px 102px; */
}

.operation .custom-select-class.__input-container.css-qbdosj-Input {
  height: 0px;
}

.operation .custom-select-class.__single-value.css-1dimb5e-singleValue {
  font-size: 14px;
}

.operation .custom-select-class span {
  margin: 5px 0px;
}

.AdvanceDashboardTabsContent {
  padding: 10px 18px;
}

/* dashboard css started */
.main-advancedashboard .user {
  justify-content: space-between;
  display: flex;
  margin: 15px 0px;
}
.main-advancedashboard .border-main {
  margin: 3px 5px;
  border: 0.5px solid white;
  height: 19px;
}
.main-advancedashboard .border-main1 {
  margin-left: -13px;
  /* margin: 3px 5px; */
  border: 0.5px solid white;
  height: 19px;
}
.main-advancedashboard .Total-Regis {
  background: #008000b5;
}

.main-advancedashboard .Total-Regis .sub-text-holder {
  background: #1987545c;
}

.main-advancedashboard .Total-Regis2 {
  background-color: #47b4c5b5;
}

.main-advancedashboard .Total-Regis2 .sub-text-holder {
  background: #47b4c5b5;
}

.main-advancedashboard .Total-Regis3 {
  background-color: #c98c09ea;
}

.main-advancedashboard .Total-Regis3 .sub-text-holder {
  background: #c98c09ea;
}

.main-advancedashboard .Total-Regis4 {
  background-color: #c62606e4;
}

.main-advancedashboard .Total-Regis4 .sub-text-holder {
  background: #c62606e4;
}

.main-advancedashboard .Total-Regis-holder {
  padding: 16px 16px 5px 16px;
}

.main-advancedashboard .sub-text-holder {
  padding: 6px 16px;
}

.main-advancedashboard p.sub-text {
  font-weight: revert;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.main-advancedashboard p.sub-text span {
  font-size: 15px;
  font-weight: 600;
}

.main-advancedashboard .sub-text-holder {
}

.main-advancedashboard .main {
  color: white;
}

.main-advancedashboard  .apexcharts-reset-icon{
  margin-right: 15px;
}


.common-modal-holder  .main-form-section  .form-label {
  font-weight: 600;
}

.main-form-section  .formfieldMain {
  position: relative;
  width: 100%;
}

.main-form-section  .formfieldMain .eyeIcon {
  position: absolute;
  top: 12px;
  right: 5px;
}


@media (min-width: 992px) and (max-width: 1199px) {
  .inestement-main {
    font-size: 13px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .inestement-main {
    font-size: 12px;
  }
}


