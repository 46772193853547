.datepickers {
    display: flex;
    gap: 20px;  /* Adjust the gap between the two inputs */
  }
  
  .from-date, .to-date {
    flex: 1;  /* Each date picker takes equal width */
  }
  
  .datepickers label {
    font-weight: bold;
  }
  
  .datepickers input {
    width: 100%; /* Ensure the input spans the full width of its container */
  }