.header .header-icons{
display:  flex;
}

.headerr{
    background-color: #fff;
}

.header .header-icons .icon{
    margin-right: 5px;
}

.header nav h1 {
    margin: 0% 20px 0px 0px;
    font-size: 20px;
    font-weight: 600;
    font-family: Helvetica;
    margin-bottom: 0px;
}

.header nav{
    border-bottom: 1px solid #80808054;
    margin :0px 22px;
}

.header .header-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .header-text p {
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 0px;
    display: flex;
    color: #99a5b5;
    justify-self: center;
    align-items: center;
}


.header .users {
    color: #99a5b5;
  }
 

  .header .header-text svg {
    font-size: 22px;
    margin-right: 5px;
}

.mobileviewlogout{
    display: none;
}
.deskviewlogout{
    display: block;
}

@media (min-width:0px) and (max-width:768px){
    .mobileviewlogout{
        display: block;
    }
    .deskviewlogout{
        display: none;
    }
    .navbar-toggler{
        display: none;
    }
  }